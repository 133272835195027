import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
  withCredentials: true,
});
