import { useEffect } from "react";

import { Box } from "@chakra-ui/react";
function ViewTask() {
  useEffect(() => {}, []);

  return <Box mb="4" bg="white" p="4" rounded="md" shadow="base"></Box>;
}

export default ViewTask;
