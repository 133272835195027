import React from "react";
import "../../App.css";
import { Image } from "@chakra-ui/react";

import imagePath from "../../AssetHelper";

function Landing() {
  return (
    <div className="App">
      <header className="App-header">
        <Image src={imagePath("logo-light.png")} alt="TextUnity Logo" />
      </header>
    </div>
  );
}

export default Landing;
