import React from "react";
import "../../App.css";

function Loading() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Loading...</p>
      </header>
    </div>
  );
}

export default Loading;
