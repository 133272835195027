// App.js

// Generic App imports
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import apiClient from "./apiCommon";
import Landing from "./home/components/Landing";
import Loading from "./home/components/Loading";

// Account Management Pages
import AccountConfirm from "./account/pages/AccountConfirm";

// Home / Marketing Pages
import Home from "./home/Home";
import HomeMain from "./home/pages/Main";
import HomePricing from "./home/pages/Pricing";
import HomeFeatures from "./home/pages/Features";
import HomeContact from "./home/pages/Contact";
import HomePrivacy from "./home/pages/Privacy";
import HomeTerms from "./home/pages/Terms";

// Dashboard Pages
import Dashboard from "./dashboard/Dashboard";
import DashboardTasks from "./dashboard/pages/Tasks";
import DashboardTaskView from "./dashboard/components/tasks/ViewTask";
import DashboardSettings from "./dashboard/pages/Settings";

// Non-logged in Task Page
import Offline from "./offline/Offline";
import OfflineViewTask from "./offline/pages/ViewTask";

// Google and MixPanel Analytics
import usePageTracking from "./hooks/usePageTracking";
// import useMixPanel from "./hooks/useMixPanel";

function App() {
  usePageTracking();
  // useMixPanel();

  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState({});
  // const [planId, setPlanId] = useState(0);

  function RequireAuth({ children }) {
    if (!auth) {
      console.log("Not logged in");
      return <Navigate to="/" />;
    }
    return children;
  }

  async function GetSession() {
    // Check for valid session
    await apiClient.get("/auth/current-session").then(({ data }) => {
      setAuth(data.auth);
      // If valid session, get remote user data
      // if (data.auth) {
      GetUser(data.auth.id);
      // }
    });
  }

  const GetUser = async (auth0id) => {
    await apiClient
      .post("/users/auth0id", {
        auth0Id: auth0id,
      })
      .then(function (resp) {
        setUser(resp.data.data.user);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetSession();
  }, []);

  if (process.env.REACT_APP_MAINTENANCE_MODE === "on") {
    return <Landing />;
  } else if (auth === null) {
    return <Loading />;
  } else {
    return (
      <>
        <div>
          <Routes>
            <Route path="/" element={<Home auth={auth} />}>
              <Route index element={<HomeMain />} />
              <Route path="features" element={<HomeFeatures />} />
              <Route path="pricing" element={<HomePricing />} />
              <Route path="contact" element={<HomeContact />} />
              <Route path="privacy" element={<HomePrivacy />} />
              <Route path="terms" element={<HomeTerms />} />
            </Route>
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard
                    user={user}
                    setUser={setUser}
                    GetUser={GetUser}
                    auth={auth}
                  />
                </RequireAuth>
              }
            >
              <Route index element={<DashboardTasks />} />
              <Route path="view/:task_id" element={<DashboardTaskView />} />
              <Route path="settings/:tab" element={<DashboardSettings />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Route>
            {/* <Route
              path="/account"
              element={
                <RequireAuth>
                  <Account
                    user={user}
                    setUser={setUser}
                    GetUser={GetUser}
                    auth={auth}
                  />
                </RequireAuth>
              }
            >
              <Route index element={<AccountPlan />} auth={auth} />
              <Route
                path="confirm/:plan_id/:session_id"
                element={<AccountConfirm />}
              />              
            </Route> */}
            <Route
              path="/subscription/confirm/:plan_id/:session_id"
              element={
                <RequireAuth>
                  <AccountConfirm
                    user={user}
                    GetUser={GetUser}
                    auth={auth}
                    setUser={setUser}
                  />
                </RequireAuth>
              }
            />
            <Route path="/loggedout" element={<Offline auth={auth} />}>
              <Route path="task/:task_id" element={<DashboardTaskView />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </>
    );
  }
}

export default App;
