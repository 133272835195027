import { ReactNode } from "react";
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";

export default function HomeDetails() {
  return (
    <Box bg={"gray.800"} position={"relative"}>
      <Flex
        flex={1}
        zIndex={0}
        display={{ base: "none", lg: "flex" }}
        position={"absolute"}
        width={"50%"}
        insetY={0}
        right={0}
      >
        <Flex
          bgGradient={"linear(to-r, gray.800 10%, transparent)"}
          w={"full"}
          h={"full"}
        />
      </Flex>
      <Container maxW={"7xl"} zIndex={10} position={"relative"}>
        <Stack direction={{ base: "column", lg: "row" }}>
          <Stack
            flex={1}
            color={"gray.400"}
            justify={{ lg: "center" }}
            py={{ base: 4, md: 20, xl: 40 }}
          >
            <Box mb={{ base: 8, md: 20 }} textAlign={"center"}>
              {/* <Text
                fontFamily={"heading"}
                fontWeight={700}
                textTransform={"uppercase"}
                mb={3}
                fontSize={"xl"}
                color={"gray.500"}
              >
                Key Benefits
              </Text> */}
              <Heading
                color={"white"}
                mb={5}
                fontSize={{ base: "3xl", md: "5xl" }}
              >
                Assign, track, manage and remind.
              </Heading>
              <Text fontSize={"xl"} color={"gray.400"}>
                The proliferation of generative AI providers and tools has
                created a complex and fragmented market. Textunity helps to
                simplify the usage and provide tools to embed it in your daily
                workflows and organization.
              </Text>
            </Box>

            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={10}
              textAlign={"center"}
            >
              {stats.map((stat) => (
                <Box key={stat.title}>
                  <Text
                    fontFamily={"heading"}
                    fontSize={"3xl"}
                    color={"white"}
                    mb={3}
                  >
                    {stat.title}
                  </Text>
                  <Text fontSize={"xl"} color={"gray.400"}>
                    {stat.content}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
          {/* <Flex flex={1} /> */}
        </Stack>
      </Container>
    </Box>
  );
}

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text as={"span"} fontWeight={700} color={"white"}>
    {children}
  </Text>
);

const stats = [
  {
    title: "Provider Management",
    content: (
      <>
        <StatsText>Connect</StatsText> all providers through a single interface
        and instantly switch between them.
      </>
    ),
  },
  {
    title: "Auditing & IP Protection",
    content: (
      <>
        <StatsText>Protect</StatsText> brand and IP data through auditing and
        blocking.
      </>
    ),
  },
  {
    title: "License Management",
    content: (
      <>
        <StatsText>Manage</StatsText> a single license per provider.
      </>
    ),
  },
  {
    title: "User & Access Control",
    content: (
      <>
        <StatsText>Maintain</StatsText> and assign access to additional users.
      </>
    ),
  },
];
